<template>
  <div>
    <CustomerDetailFiles></CustomerDetailFiles>
  </div>
</template>

<script>
import CustomerDetailFiles from "@/components/admin/customerDetailFiles";
export default {
  components: { CustomerDetailFiles },
};
</script>

<style>

</style>