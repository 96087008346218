var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"customer-details"},[_c('div',{staticClass:"mb-2"},[_c('userInfoCard')],1),_c('h3',[_c('router-link',{attrs:{"to":`/admin/kunden/${_vm.$route.params.id}/${_vm.$route.params.year}`}},[_c('font-awesome-icon',{attrs:{"icon":_vm.faArrowLeft}})],1),_vm._v(" "+_vm._s(_vm.$t(_vm.$route.params.type))+" "),_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.count))])],1),_c('p',[_vm._v(_vm._s(_vm.$t("documentsDescription")))]),_c('div',{staticClass:"d-flex align-items-center"},[_c('h6',{staticClass:"text-blue mb-0 mr-2"},[_vm._v(_vm._s(_vm.$t("userNote"))+":")]),_c('span',[_vm._v(_vm._s(_vm.note))])]),(
      _vm.$route.params.type == 'Notice' ||
      _vm.$route.params.type == 'CalculationList'
    )?_c('div',{staticClass:"col-12 col-lg-8 offset-lg-2 mt-4"},[_c('input',{staticClass:"hide",attrs:{"type":"file","name":"imageUpload","id":"imageUpload","multiple":"","accept":"image/*,.pdf,.doc,.docx"},on:{"change":_vm.getFileFromInput}}),_c('label',{staticClass:"document-download-box col-12",attrs:{"for":"imageUpload"},on:{"dragover":function($event){$event.preventDefault();}}},[_c('font-awesome-icon',{attrs:{"icon":_vm.faArrowUpFromBracket}}),_c('p',[_vm._v(_vm._s(_vm.$t("selectUploadDocument")))])],1)]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"text-center my-4"},[_c('b-spinner',{staticStyle:{"width":"2rem","height":"2rem"},attrs:{"variant":"primary"}})],1):(_vm.count === 0)?_c('div',{staticClass:"text-center my-4"},[_c('img',{attrs:{"width":"180","src":_vm.noFoundImage,"alt":_vm.$t('noFoundData')}}),_c('p',[_vm._v(" "+_vm._s(_vm.$t("noFoundData"))+" ")])]):_c('div',[_c('div',{staticClass:"c-customer-detail row mt-4"},_vm._l((_vm.documents),function(document,i){return _c('div',{key:i,staticClass:"c-customer-detail__item"},[_c('div',{staticClass:"w-100 d-flex justify-content-end mb-3"},[_c('button',{staticClass:"blue-button mr-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.DOWNLOAD_FILE(document)}}},[_c('font-awesome-icon',{attrs:{"icon":_vm.faArrowDown}})],1),(
              _vm.$route.params.type === 'Notice' ||
              _vm.$route.params.type === 'CalculationList'
            )?_c('button',{staticClass:"blue-button bg-danger mr-2",attrs:{"type":"button"},on:{"click":() => _vm.deleteFiles(i, document)}},[_c('font-awesome-icon',{attrs:{"icon":_vm.faTimes}})],1):_vm._e()]),(_vm.isLoadingFile)?_c('div',{staticClass:"c-customer-detail__item__flayer"},[_c('b-spinner',{staticStyle:{"width":"2rem","height":"2rem"},attrs:{"variant":"primary"}})],1):_vm._e(),_c('img',{staticClass:"c-customer-detail__item__icon",attrs:{"src":require(`@/assets/img/icon/${_vm.fileType(document.name)}.svg`),"alt":""}}),_c('div',{staticClass:"mt-4 text-center"},[_c('div',{staticClass:"u-line-1"},[_c('small',[_c('strong',[_vm._v(_vm._s(document.name))])])]),_c('div',[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(_vm.formatSizeUnits(document.size || 0)))])])])])}),0)]),_c('b-modal',{attrs:{"centered":"","hide-footer":"","hide-header":""},model:{value:(_vm.failDownloadModal),callback:function ($$v) {_vm.failDownloadModal=$$v},expression:"failDownloadModal"}},[_c('div',{staticClass:"text-center my-3"},[_c('img',{attrs:{"src":require("@/assets/img/icon/no-found-document.png"),"width":"200","alt":"no-found-document"}}),_c('p',[_vm._v(_vm._s(_vm.$t("noFoundDocumentDescription")))]),_c('div',{staticClass:"mt-3"},[_c('button',{staticClass:"blue-button",on:{"click":function($event){_vm.failDownloadModal = false}}},[_vm._v(" "+_vm._s(_vm.$t("close"))+" ")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }