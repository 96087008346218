import { baseUrl } from '../Api';
import localStorageManager from '../localStorageManager';

/** download token */
export const downloadToken = (url, data) => {
    const token = localStorageManager.userAuth.getUserToken()

    try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        var requestOptions = {
            method: "POST",
            redirect: "follow",
            headers: myHeaders,
            body: JSON.stringify(data)
        };

        return fetch(`${baseUrl}${url}`, requestOptions)
            .then((response) => {
                return response.blob()
            })
            .then((result) => {
                return URL.createObjectURL(result);
            })
            .catch((error) => {
                return 'fail'
            });
    } catch (error) {
        return 'fail'
    }
}