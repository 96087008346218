import { getToken } from "../Api/getToken"
import { downloadToken } from "../Api/downloadToken"

// Dosya  getirme
export const getFileByUserId = (type, userId, year) => {
    return getToken(`/File?categoryTypeId=${type}&userId=${userId}&taxYear=${year}`)
}

// Tüm dosyaları  getirme
export const getFiles = (userId, year) => {
    return getToken(`/File/All?userId=${userId}&taxYear=${year}`)
}

// Dosya  getirme
export const downloadFile = (data) => {
    return downloadToken(`/File/DownloadFile`, data)
}