<template>
  <div class="customer-details">
    <!-- <div class="mb-4">
      <router-link :to="`/admin/kunden/${$route.params.id}`">
        <button class="blue-button">
          <font-awesome-icon :icon="faChevronLeft" />
          {{ $t("back") }}
        </button>
      </router-link>
    </div> -->
    <div class="mb-2">
      <userInfoCard></userInfoCard>
    </div>
    <h3>
     <router-link :to="`/admin/kunden/${$route.params.id}/${$route.params.year}`">
       <font-awesome-icon :icon="faArrowLeft" />
     </router-link>
      {{ $t($route.params.type) }}
      <span class="text-muted">{{ count }}</span>
    </h3>
    <p>{{ $t("documentsDescription") }}</p>
    <div class="d-flex align-items-center">
      <h6 class="text-blue mb-0 mr-2">{{ $t("userNote") }}:</h6>
      <span>{{ note }}</span>
    </div>
    <div
      class="col-12 col-lg-8 offset-lg-2 mt-4"
      v-if="
        $route.params.type == 'Notice' ||
        $route.params.type == 'CalculationList'
      "
    >
      <input
        @change="getFileFromInput"
        type="file"
        name="imageUpload"
        id="imageUpload"
        multiple
        class="hide"
        accept="image/*,.pdf,.doc,.docx"
      />
      <label
        @dragover.prevent
        for="imageUpload"
        class="document-download-box col-12"
      >
        <font-awesome-icon :icon="faArrowUpFromBracket" />
        <p>{{ $t("selectUploadDocument") }}</p>
        <!-- <p class="mt-4">{{ $t("maxiumumLimit") }}</p> -->
      </label>
    </div>
    <div class="text-center my-4" v-if="isLoading">
      <b-spinner
        variant="primary"
        style="width: 2rem; height: 2rem"
      ></b-spinner>
    </div>
    <div class="text-center my-4" v-else-if="count === 0">
      <img width="180" :src="noFoundImage" :alt="$t('noFoundData')" />
      <p>
        {{ $t("noFoundData") }}
      </p>
    </div>
    <div v-else>
      <div class="c-customer-detail row mt-4">
        <div
          class="c-customer-detail__item"
          v-for="(document, i) in documents"
          :key="i"
        >
          <div class="w-100 d-flex justify-content-end mb-3">
            <button
              class="blue-button mr-2"
              type="button"
              @click="DOWNLOAD_FILE(document)"
            >
              <font-awesome-icon :icon="faArrowDown" />
              <!-- <b-spinner variant="light" v-else></b-spinner> -->
            </button>
            <button
              class="blue-button bg-danger mr-2"
              type="button"
              v-if="
                $route.params.type === 'Notice' ||
                $route.params.type === 'CalculationList'
              "
              @click="() => deleteFiles(i, document)"
            >
              <font-awesome-icon :icon="faTimes" />
              <!-- <b-spinner variant="light" v-else></b-spinner> -->
            </button>
          </div>
          <div v-if="isLoadingFile" class="c-customer-detail__item__flayer">
            <b-spinner
              variant="primary"
              style="width: 2rem; height: 2rem"
            ></b-spinner>
          </div>
          <img
            :src="require(`@/assets/img/icon/${fileType(document.name)}.svg`)"
            class="c-customer-detail__item__icon"
            alt=""
          />
          <div class="mt-4 text-center">
            <div class="u-line-1">
              <small
                ><strong>{{ document.name }}</strong></small
              >
            </div>
            <div>
              <small class="text-muted">{{
                formatSizeUnits(document.size || 0)
              }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal centered v-model="failDownloadModal" hide-footer hide-header>
      <div class="text-center my-3">
        <img
          src="@/assets/img/icon/no-found-document.png"
          width="200"
          alt="no-found-document"
        />
        <p>{{ $t("noFoundDocumentDescription") }}</p>
        <div class="mt-3">
          <button @click="failDownloadModal = false" class="blue-button">
            {{ $t("close") }}
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
  faArrowUpFromBracket,
  faChevronLeft,
  faArrowLeft,
  faTimes,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { getFileByUserId, downloadFile } from "../../../services/modules/File";
const noFoundImage = require("@/assets/images/no-found.png");
import userInfoCard from "../userInfo/userInfoCard.vue";
import {
  deleteDocumentFile,
  sendDocument,
} from "../../../services/modules/User";
import { documentNote } from "../../../services/modules/Admin";
export default {
  components: { FontAwesomeIcon, userInfoCard },
  data() {
    return {
      noFoundImage,
      faChevronLeft,
      faArrowLeft,
      faArrowUpFromBracket,
      faTimes,
      faArrowDown,
      isLoading: true,
      documents: [],
      documentFiles: [],
      count: 0,
      imageTypes: ["jpg", "jpeg", "gif", "png", "pjpeg", "x-png", "webp"],
      failDownloadModal: false,
      isLoadingFile: false,
      note: "",
    };
  },
  methods: {
    async deleteFiles(i, file) {
      const response = await deleteDocumentFile(
        this.$route.params.type,
        file,
        this.$route.params.id,
        this.$route.params.year
      );

      if (response.message === "OK") {
        this.documents.splice(i, 1);
      }
    },
    async getFileFromInput(e) {
      this.documentFiles = [...this.documentFiles, ...e.target.files];
      const response = await sendDocument(
        this.documentFiles,
        this.$route.params.type,
        this.$route.params.id,
        this.$route.params.year
      );

      if (response.message === "OK") {
        location.reload();
      }
    },
    fileType(name) {
      const pathArr = name.split(".");
      if (pathArr.length > 0) {
        const path = pathArr[pathArr.length - 1];
        if (path === "pdf") return "pdf";
        else if (this.imageTypes.includes(path)) return "image";
        else return "document";
      }
    },
    formatSizeUnits(bytes) {
      if (bytes >= 1073741824) {
        bytes = (bytes / 1073741824).toFixed(2) + " GB";
      } else if (bytes >= 1048576) {
        bytes = (bytes / 1048576).toFixed(2) + " MB";
      } else if (bytes >= 1024) {
        bytes = (bytes / 1024).toFixed(2) + " KB";
      } else if (bytes > 1) {
        bytes = bytes + " bytes";
      } else if (bytes == 1) {
        bytes = bytes + " byte";
      } else {
        bytes = "0 bytes";
      }
      return bytes;
    },
    async getFiles() {
      this.isLoading = true;
      const response = await getFileByUserId(
        this.$route.params.type,
        this.$route.params.id,
        this.$route.params.year
      );
      if (response.message === "OK") {
        this.documents = response.data.items;
        this.count = response.data.count;
      } else {
        this.documents = [];
        this.count = 0;
      }
      this.isLoading = false;
    },
    async getNoteDocument() {
      const response = await documentNote(
        this.$route.params.id,
        this.$route.params.year,
        this.$route.params.type
      );

      if (response.message === "OK") {
        this.note = response.data.plainText;
      } else if (response.message === "NOT_FOUND") {
        this.note = this.$t("notFoundNote");
      }
    },
    async DOWNLOAD_FILE(document, download = false) {
      const windowReference = window.open();
      if (this.isLoadingFile) return;
      this.isLoadingFile = true;
      const data = {
        userId: this.$route.params.id,
        taxYear: this.$route.params.year,
        categoryTypeId: this.$route.params.type,
        fileName: document.name,
      };
      const response = await downloadFile(data);
      if (response === "fail") this.failDownloadModal = true;
      // else if (!download) window.open(response, "_blank");
      else windowReference.location.assign(response);
      this.isLoadingFile = false;
    },
  },
  mounted() {
    this.getFiles();
    this.getNoteDocument();
  },
};
</script>

<style lang="scss">
.customer-details {
  .hide {
    display: none;
  }
  .document-download-box {
    border: 1px dashed #161640;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 0px;
    color: #161640;

    svg {
      font-size: 50px;
    }

    p {
      font-size: 10px;
      margin-top: 6px;
      font-weight: bold;
    }
  }

  .blue-button {
    padding: 4px 10px;
  }
}
</style>
